import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import Breadcrumbs from "gatsby-theme-wirehead-bulma/src/breadcrumbs"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXSharpImg } from '../components/image';
import SchemaBlogPosting from "../components/schema-blogposting"

export default function PageTemplate({ data, location, pageContext }) {
  const { mdx, allSitePage } = data // data.markdownRemark holds your post data
  const { frontmatter, body } = mdx

  const imgs = {}

  if (frontmatter.images) {
    frontmatter.images.forEach((image, i) => {
      const { childImageSharp, publicURL } = image;
      const { fluid } = childImageSharp;
      imgs[`Img${i + 1}`] = ({ align, width }) =>
        <MDXSharpImg align={align} width={width} fluid={fluid || undefined} />        
    });
  }

  var pages = data.allSitePage.edges.map(article => {
    return (
      <li>
        <Link
          to={article.node.path}
          key={article.node.path}
          className="navbar-item"
        >
          {article.node.context.title}
        </Link>
      </li>
    )
  })

  return (
    <Layout posted={pageContext.date} updated={pageContext.updated} >
      <SEO
        pathname={location.pathname}
        title={pageContext.title}
        description={pageContext.excerpt} />
    
      <SchemaBlogPosting
        pathname={location.pathname}
        title={pageContext.title}
        datePublished={pageContext.date}
        breadcrumbs={pageContext.breadcrumbs}
      />
    <Breadcrumbs
      depth={pageContext.depth}
      breadcrumbs={pageContext.breadcrumbs}
    />
    <h1 className="title is-2">{pageContext.title}</h1>
    <section className="section">
    <div className="content">
    <MDXRenderer imgs={imgs} pages={allSitePage}>{body}</MDXRenderer>
    </div>
    </section>
    <hr />
    {pages}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String, $path: String) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        type
        description
        images {
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      excerpt(pruneLength: 300)
    }
    allSitePage(filter: { context: { parent: { eq: $path } } }) {
      edges {
        node {
          context {
            id
            depth
            parent
            title
            date
          }
          path
        }
      }
    }
  }
`
